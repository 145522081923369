.navbar-transparent {
    background-color: transparent !important;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }

  .bg-white {
    background-color: white !important;
  }
  
  .navbar-dark, .navbar[data-bs-theme=dark] {
    --bs-navbar-color: rgba(255, 255, 255);
    --bs-navbar-hover-color: var(--bs-success);
    --bs-navbar-disabled-color: rgba(255, 255, 255, .25);
    --bs-navbar-active-color: #fff;
    --bs-navbar-brand-color: #fff;
    --bs-navbar-brand-hover-color: #fff;
    --bs-navbar-toggler-border-color: rgba(255, 255, 255, .1);
    --bs-navbar-toggler-icon-bg: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0) !important;
}

/* Navbar scroll progress bar */
.scroll-progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px; /* Thickness of the progress bar */
    background-color: var(--bs-primary); /* Color of the progress bar */
    transition: width 0.1s ease-in-out;
    z-index: 1000; /* Ensure it appears above other elements */
  }


  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0 !important;
}

button:hover {
  border-color: #00000000 !important;
}

.bi-navbar{
  font-size: 2rem;
}