/* Custom styles */
.hero {
  padding: 0 0 0px;
  position: relative;
  background: linear-gradient(to bottom right, #fff, #f8f9fa);
}

.carousel-background {
  position: relative;
  width: 100%;
}

.stat-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  width: 12rem;
  height: 12rem !important;
  border-bottom: var(--bs-success) 4px solid !important;
}

.stat-card:hover {
  transform: translateY(-5px);
}

.btn-warning {
  background-color: #ffd60a;
  border-color: #ffd60a;
  color: #000;
}

.btn-warning:hover {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #000;
}

.btn-primary:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-success);
  border-color: var(--bs-success);
}

.btn-primary:active {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-success) !important;
  border-color: var(--bs-success) !important;
}

.navbar {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* padding: 1rem 0; */
}

.feature {
  transition: transform 0.3s ease;
  padding: 1.5rem;
  border-radius: 10px;
}

.feature:hover {
  transform: translateX(10px);
  background: #112d422f;
}

.automation-image {
  position: sticky;
  top: 0;
  /* Sticks the image to the top as you scroll */
  z-index: 10;
  /* Ensures the image stays on top of the text */
  max-height: 100vh;
  /* Ensure the image doesn't stretch too large */
  transition: all 0.3s ease-in-out;
}

.selected-feature {
  background-color: #112d421b;
  /* border-left: 4px solid var(--bs-primary); */
  /* padding-left: 10px; */
  transition: background-color 0.3s ease, border-color 0.3s ease;
}


.automation-image img,
.insights-image img,
.create-ads-image img,
.hero-image img {
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  max-width: 100%;
  height: auto;
}

.automation-image img:hover,
.insights-image img:hover,
.create-ads-image img:hover,
.hero-image img:hover {
  transform: translateY(-10px);
}

.footer {
  /* border-top: var(--bs-primary) 5px solid; */
  border-radius: 3rem;
  color: #000 !important;
  background-color: #f8f9fa !important;
}

.footer a {
  transition: color 0.3s ease;
  color: #3c3c3c !important;
}

.footer a:hover {
  color: var(--bs-secondary) !important;
}

.footer-logo {
  width: 10rem;
}

section {
  padding: 80px 0;
}

.stats {
  background-color: #f8f9fa;
  margin-top: -5rem;
  padding-top: 10rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .hero {
    /* padding: 100px 0 60px; */
    text-align: center;
  }

  .hero .btn-group {
    justify-content: center;
  }

  .feature {
    text-align: left;
  }

  .navbar .dropdown-menu {
    border: none;
    background: transparent;
    padding-left: 1rem;
  }

  .navbar .dropdown-item {
    color: rgba(0, 0, 0, .55);
  }

  .navbar .dropdown-item:hover {
    background: transparent;
    color: rgba(0, 0, 0, .7);
  }

  section {
    padding: 60px 0;
  }

  .stats {
    margin-top: -60px;
    padding-top: 120px;
  }
}

/* Additional responsive improvements */
@media (max-width: 576px) {
  .display-4 {
    font-size: 2.5rem;
  }

  .display-5 {
    font-size: 2rem;
  }

  .lead {
    font-size: 1.1rem;
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}


.bg-img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-0 {
  background-image: url("./assets/images/bg-0.jpg");
}

.bg-1 {
  background-image: url('./assets/images/bg-0.jpg');
}

.whyus-icon {
  color: var(--bs-success);
}