@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Oooh+Baby&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

/* :root {
  font-family: "Poppins", system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
/* "Manrope", sans-serif */
html {
  scroll-behavior: smooth;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}

a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  font-family: "Manrope", serif !important;
  /* display: flex;
  place-items: center; */
  min-width: 320px;
  min-height: 100vh;
}

.title-tops{
  font-family: "Oooh Baby", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 2rem;
  margin-bottom: -1.25rem;
  color: var(--bs-success);
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

h2{
  font-weight: 800 !important;  
}

a.btn {
  border-radius: 5rem !important;
  padding: 0.5rem 1rem;
}

button {
  border-radius: 5rem !important;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}

button:hover {
  border-color: #646cff;
}

button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }

  a:hover {
    color: #747bff;
  }

  button {
    background-color: #f9f9f9;
  }
}