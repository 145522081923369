.team-section {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 30px;
  }
  
  .team-card {
    border: none;
    background-color: #fff;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .team-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .team-img {
    height: 250px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .card-title {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .card-subtitle {
    font-size: 1rem;
    font-weight: 500;
  }
  
  .card-text {
    font-size: 0.9rem;
    color: #6c757d;
  }
  